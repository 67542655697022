<section class="alicen mb-2">

    <div class="container">
        <h2 class="weltitle titlecolor">Padmam Yoga Gallery</h2>
        <div class="col-md-12 px-0 mb-2">

            <div class="col-sm-3">

                <p class="page-header">2014 - Events</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/258829.jpg" width="200" height="200"
                        title="2014 - Events&nbsp;/&nbsp;2014 - Events" />
                </div>
            </div>
            <div class="col-sm-3">

                <p class="page-header">2014 - Events</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/992623.jpg" width="200" height="200"
                        title="2014 - Events&nbsp;/&nbsp;2014 - Events" />
                </div>
            </div>
            <div class="col-sm-3">

                <p class="page-header">2014 - Events</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/876253.jpg" width="200" height="200"
                        title="2014 - Events&nbsp;/&nbsp;2014 - Events" />
                </div>
            </div>
            <div class="col-sm-3">

                <p class="page-header">2014 - Events</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/103893.jpg" width="200" height="200"
                        title="2014 - Events&nbsp;/&nbsp;2014 - Events" />
                </div>
            </div>
            <div class="col-sm-3">

                <p class="page-header">2014 - Events</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/929972.jpg" width="200" height="200"
                        title="2014 - Events&nbsp;/&nbsp;2014 - Events" />
                </div>
            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas24</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/19170.jpg" width="200" height="200"
                        title="2014 - Events&nbsp;/&nbsp;Asanas24" />
                </div>
            </div>
            <div class="col-sm-3">

                <p class="page-header">2014 - Events</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/215446.jpg" width="200" height="200"
                        title="2014 - Events&nbsp;/&nbsp;2014 - Events" />
                </div>
            </div>
            <div class="col-sm-3">

                <p class="page-header">2014 - Events</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/840980.jpg" width="200" height="200"
                        title="2014 - Events&nbsp;/&nbsp;2014 - Events" />
                </div>
            </div>

        </div>
    </div>


</section>