<div class="center mb-2">
    <h2 class="titlecen">International Yoga Day</h2>
</div>

<div class="container">

    <div class="col-md-12 px-0 mb-2">
        <div class="text-center">
            <img src="./assets/images/yogaday.png" alt="International Yoga Day" class="yoga-day" style="width: 60em;" />
        </div>


        <div class="text-center mt-2"> <a class="btnnew" href="Form/eventdownload/application_yoga_day.docx"
                download><strong>Download
                    Application</strong></a> </div>

        <br>




    </div>



</div>