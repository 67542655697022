<section class="alicen mb-2">


    <div class="container">
        <h2 class="weltitle titlecolor">Asanas Type</h2>
        <div class="col-md-12 px-0 mb-2">

            <div class="col-sm-3">

                <p class="page-header">Asanas06</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/194227.png"
                        title="Asanas06&nbsp;/&nbsp;Asanas06" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas09</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/422270.png"
                        title="Asanas09&nbsp;/&nbsp;Asanas09" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas01</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/614766.png"
                        title="Asanas01&nbsp;/&nbsp;Asanas01" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas02</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/282365.png"
                        title="Asanas02&nbsp;/&nbsp;Asanas02" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas03</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/111942.png"
                        title="Asanas03&nbsp;/&nbsp;Asanas03" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas04</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/997196.png"
                        title="Asanas04&nbsp;/&nbsp;Asanas04" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas05</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/9201.png"
                        title="Asanas05&nbsp;/&nbsp;Asanas05" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas10</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/244774.png"
                        title="Asanas10&nbsp;/&nbsp;Asanas10" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas07</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/678636.png"
                        title="Asanas07&nbsp;/&nbsp;Asanas07" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas08</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/891223.png"
                        title="Asanas08&nbsp;/&nbsp;Asanas08" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas11</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/537725.png"
                        title="Asanas11&nbsp;/&nbsp;Asanas11" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas12</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/328096.png"
                        title="Asanas12&nbsp;/&nbsp;Asanas12" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas13</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/734732.png"
                        title="Asanas13&nbsp;/&nbsp;Asanas13" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas14</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/51181.png"
                        title="Asanas14&nbsp;/&nbsp;Asanas14" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas15</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/985184.png"
                        title="Asanas15&nbsp;/&nbsp;Asanas15" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas16</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/883387.png"
                        title="Asanas16&nbsp;/&nbsp;Asanas16" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas17</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/925063.png"
                        title="Asanas17&nbsp;/&nbsp;Asanas17" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas18</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/2585.png"
                        title="Asanas18&nbsp;/&nbsp;Asanas18" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas19</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/357302.png"
                        title="Asanas19&nbsp;/&nbsp;Asanas19" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas20</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/237976.png"
                        title="Asanas20&nbsp;/&nbsp;Asanas20" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas21</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/794030.png"
                        title="Asanas21&nbsp;/&nbsp;Asanas21" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas22</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/904942.png"
                        title="Asanas22&nbsp;/&nbsp;Asanas22" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas23</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/966007.png"
                        title="Asanas23&nbsp;/&nbsp;Asanas23" />
                </div>

            </div>
            <div class="col-sm-3">

                <p class="page-header">Asanas24</p>
                <div class="resizeimage"><img src="./assets/images/gallery/user_images/973751.png"
                        title="Asanas24&nbsp;/&nbsp;Asanas24" />
                </div>

            </div>

        </div>
    </div>



</section>