<div style="display: table;">

  <div class="col-md-12 px-0">
    <h2 class="titlecen">Welcome to Padmam Yoga!</h2>
  </div>
  <div class="col-md-12 pl-50px pr-50px mt-2 mb-2">

    <div class="col-sm-6">
      <h3 class="weltitle2 mb-2">About Us</h3>
      <p class="welsubtitle">Our Padmam yoga integrates ancient holistic teachings with the Hatha and Raja Yoga practice
        for the mind body and soul. Our complete yoga Program covers all aspects of practicing asanas Pranayama and
        meditation.</p>
      <p class="welsubtitle">Padmam yoga offers a beautiful space and incredible support on your journey discovering
        your personal practice. We help you find the connection to your inner being and your voice and style in
        teaching. Our yoga Training provides a very strong foundation in proper alignment and helps maintain the body in
        its optimum state.</p>
    </div>

    <div class="col-sm-6">
      <h2 class="weltitle2 mb-2">Our Profile</h2>
      <p class="welsubtitle">Padmam Yoga training & Research Treatment Centre is established in Chennai Tamilnadu
        (southern Part India) in the year 2010 by Yogachemmal M.Ravi Arumugam. His sincere dedication to the art of yoga
        has lead him to develop a Yoga centre to offering Yoga Training, Yoga theraphy, projects that lead yoga to the
        rural era.
      </p>
      <p class="welsubtitle">Ravi Arumugam with his dedication and great level of practice has brought awareness to
        thousands of people in Tamilnadu and India. He is one among a few existing in Tamilnadu to develop yoga to all
        the remote corners of Tamilnadu and India.</p>
    </div>

  </div>
  <div class="col-md-12 pl-50px pr-50px mb-2 mt-2">
    <h2 class="weltitle2 mb-2">About Padmam Yoga Trust</h2>
    <p class="welsubtitle mb-2">Trust registered as padmam Yoga And Natural Living Development Trust that propagates yoga
      and its benefits to all the people in need.
      If you are willing to develop the art of yoga, You can donate any amount that will help him continue this
      charity. For Indian citizens all the donations received are exempted from tax under section 80G.
      You can support Padmam Yoga trust by Making a Donation . Please note that you may also make a donation directly
      to the Padmam YogaTrust by sending us the cheque or DD in favour of padmam Yoga and Natural Living Development
      Trust payable at chennai, India. The Managing Trustee - Ravi Arumugam</p>
  </div>
  <div class="col-md-12 partitionleft pl-50px pr-50px">
    <div class="col-md-9">
      <h2 class="title">About the Director</h2>

      <p class="subtitle">He has dedicated innumerable Yoga camps to the village people and school students and he
        believes that practicing Yoga in young age should definitely keep them healthy and and reduce medical bills. So
        he founded a Trust registered as padmam Yoga And Natural Living Development Trust that propagates yoga and its
        benefits to all the people in need. He is also the General Secretary of<strong> TAMILNADU SPORTS YOGASANA
          ASSOCIATION</strong>, a registered body (Regn No 531/2014) </p>
    </div>
    <div class="col-md-3 text-end">
      <img src="./assets/images/teacher1.png" alt="The Managing Trustee - Ravi Arumugam">
    </div>


  </div>
</div>
