<div class="slide">
    <!-- <div class="slidetext">
        <h2>8<sup>th</sup> SouthIndia Yogasana Championship -
            {{currentYear}}
        </h2>
        <p>Organised by: Padmam Yoga and Natural Living Development Trust <br /> Venue: Jawaharlal Nehru Indoor Stadium
            | Date: 11th August, 2018</p>
    </div> 
    <div class="slideimage"> <img class="first" src="./assets/images/event.jpg" /> <img class="second"
            src="./assets/images/event_mo.jpg" />
    </div>-->
    <div class="slideimage">
        <img src="./assets/images/yoga-brochure-12.jpg" />
    </div>
</div>

<div class="container">
    <div class="col-md-12 px-0 mb-2">
        <div class="text-center"><img alt="Padmam Yoga Logo" src="./assets/images/logoimg.png" /></div>
        <h2 class="weltitle">Welcome to Padmam Yoga</h2>
        <p class="welsubtitle">Our Padmam yoga integrates ancient holistic teachings with the Hatha and Raja Yoga
            practice for the mind body and soul. Our complete yoga Program covers all aspects of practicing asanas
            Pranayama and meditation.
            Padmam yoga offers a beautiful space and incredible support on your journey discovering your personal
            practice. We help you find the connection to your inner being and your voice and style in teaching. Our
            yoga Training provides a very strong foundation in proper alignment and helps maintain the body in its
            optimum state. </p>
        <p class="welsubtitle">Padmam Yoga training &amp; Research Treatment Centre is established in Chennai
            Tamilnadu (southern Part India) in the year 2010 by Yogachemmal M.Ravi Arumugam. His sincere dedication
            to the art of yoga has lead him to develop a Yoga centre to offering Yoga Training, Yoga theraphy,
            projects that lead yoga to the rural era.</p>
        <ul class="list-flex-none mt-2">
            <li><a class="btnnew mr-1" href="/about">Read More</a></li>
            <li><a class="btnnew mr-1" href="https://event.padmamyogaa.org">Event
                    Registration</a></li>
            <li><a class="btnnew" href="./assets/downloads/yoga.pdf" download="">Download Here</a></li>
        </ul>
    </div>
</div>
<div class="container fullwidth pl-50px pr-50px partitionleft">
    <div class="col-sm-6">
        <h2 class="title">About Yoga &amp; its Benefits</h2>
        <p class="subtitle">The art of practicing yoga helps in controlling an individual's mind, body and soul.
            It brings together physical and mental disciplines to achieve a peaceful body and mind; it helps
            manage stress and anxiety and keeps you relaxing. It also helps in increasing flexibility, muscle
            strength and body tone. It improves respiration, energy and vitality. Practicing yoga might seem
            like just stretching, but it can do much more for your body from the way you feel, look and move.
            <br /><br />Yoga asanas build strength, flexibility and confidence. Regular practice of yoga can
            help lose weight, relieve stress, improve immunity and maintain a healthier lifestyle.
        </p>
    </div>
    <div class="col-sm-6"> <iframe width="100%" height="350" frameborder="0" allowfullscreen="" class="iframehome"
            src="https://www.youtube.com/embed/5Y2i-ma0s_k"></iframe>
    </div>
</div>