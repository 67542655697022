<div class="center mb-2">
  <h2 class="titlecen">Download Brochure</h2>
</div>

<div class="container">

  <div class="col-md-12 px-0 mb-2">
      <div class="text-center">
          <img src="./assets/images/yoga-brochure-12.jpg" alt="Download Brochure" class="yoga-day" style="width: 60em;" />
      </div>


      <div class="text-center mt-2"> <a class="btnnew" href="./assets/downloads/2024_May_12th_South_India_Yoga_Broucher_005.pdf"
              download><strong>Download
                  Brochure</strong></a> </div>

      <br>




  </div>



</div>