import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { YogaComponent } from './yoga/yoga.component';
import { AsanasComponent } from './asanas/asanas.component';
import { MediaComponent } from './media/media.component';
import { JoinyogatodayComponent } from './joinyogatoday/joinyogatoday.component';
import { ContactComponent } from './contact/contact.component';
import { DownloadPamphletComponent } from './download-pamphlet/download-pamphlet.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    YogaComponent,
    AsanasComponent,
    MediaComponent,
    JoinyogatodayComponent,
    ContactComponent,
    DownloadPamphletComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      { path: 'about', component: AboutComponent },
      { path: 'yoga', component: YogaComponent },
      { path: 'asanas', component: AsanasComponent },
      { path: 'media', component: MediaComponent },
      { path: 'joinyogatoday', component: JoinyogatodayComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'download-pamphlet', component: DownloadPamphletComponent },
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
