<header>
  <div class="top-nav">
    <div id="topcontent">
      <div id="leftside">&nbsp;
      </div>
      <div id="rightside">
        <span class="fa fa-phone"></span> +91 900 328 8211 | <span
          class="glyphicon glyphicon-envelope"></span> padmamyoga2010@gmail.com
      </div>
    </div>
  </div>
  <nav class="navbar navbar-inverse navbar-static-top">
    <div class="container-fluid">
      <div class="navbar-header"> <button data-toggle="collapse" data-target="#myNavbar" class="navbar-toggle"
          type="button"><i class="fa fa-bars" aria-hidden="true"></i>
        </button> <a href="#"><img alt="Company Name" width="90%" src="./assets/images/common/logo.png" /></a>
      </div>
      <div id="myNavbar" class="collapse navbar-collapse">
        <ul class="nav navbar-nav">
          <li><a href="/"> Home</a></li>
          <li> <a href="/about">About</a></li>
          <li><a href="/yoga">Yoga</a></li>
          <li><a href="/asanas">Asanas</a></li>
          <li><a href="/media">Media</a></li>
          <li><a href="/download-pamphlet">Download Brochure</a></li>
          <li><a href="/joinyogatoday">International Yoga Day</a></li>
          <li><a href="https://event.padmamyogaa.org" target="_blank">Event Registration</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<div class="content-area">
  <router-outlet></router-outlet>
</div>
<footer>
  <div class="container">
    <div class="row">

      <div class="col-sm-3">
        <table class="fullwidth">
          <tr>
            <td><a href="/">Home</a></td>
          </tr>
          <tr>
            <td><a href="/about">About</a></td>
          </tr>
          <tr>
            <td><a href="/joinyogatoday">International Yoga Day</a></td>
          </tr>
          <tr>
            <td><a href="/contact">Contact</a></td>
          </tr>
        </table>
      </div>

      <div class="col-sm-3">
        <table class="fullwidth">
          <tr>
            <td><a href="/asanas">Yoga Asanas</a></td>
          </tr>
          <tr>
            <td><a href="/media">Gallery</a></td>
          </tr>
        </table>
      </div>


      <div class="col-sm-3">
        <p>Office Address:</p>
        <p>No.35, Sakthi Nagar Main Rd, Near PRIME CARE NURSING HOME, Balaji Nagar, Adambakkam, Chennai- 600088</p>
        <p>Contact No:  +91 900 328 8211</p>
      </div>
      <div class="col-sm-3">
        <p>Like & Subscribe us on:</p>
        <!-- <a href="#"><i id="social-fb" class="fa fa-facebook-square fa-2x social"></i></a>
        <a href="#"><i id="social-gp" class="fa fa-youtube-square fa-2x social"></i></a> -->

      </div>
    </div>
    <hr>
    <div class="bottombar">
      Copyright ©
      {{currentYear}} Padmam Yoga

    </div>
  </div>
</footer>
