import { Component, OnInit } from '@angular/core';
import { Email } from '../shared/email.model';
import { EmailService } from '../shared/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styles: [
  ]
})
export class ContactComponent implements OnInit {
  SendEmail: Email;
  constructor(public service: EmailService) { }

  ngOnInit(): void {
    this.SendEmail = new Email();
  }
  ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }
  ErrorMessage: string = "";
  IsEmailSent: boolean = false;
  send(SendEmail) {
    if (!Boolean(SendEmail.name)) {
      this.ErrorMessage = "Please enter Name";
    }
    else if (!Boolean(SendEmail.mobile)) {
      this.ErrorMessage = "Please enter Mobile";
    }
    else if (!Boolean(SendEmail.email)) {
      this.ErrorMessage = "Please enter Email";
    }
    else if (!this.ValidateEmail(SendEmail.email)) {
      this.ErrorMessage = "Please enter valid Email";
    }
    else if (!Boolean(SendEmail.message)) {
      this.ErrorMessage = "Please enter Message";
    }
    else {
      this.service.sendEmail('Email', SendEmail)
        .subscribe(res => {
          console.log(res);
          this.SendEmail = new Email();
          this.ErrorMessage = '';
          this.IsEmailSent = true;
        },
        (error) => {
          this.ErrorMessage = 'Message not received. please contact administrator at info@padmamyogaa.org';
            console.log(error);
        })
    }
  }
}
