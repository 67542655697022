import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Email } from './email.model';
import { EnvironmentUrlService } from './environment-url.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private http:HttpClient,private _envUrl: EnvironmentUrlService) { }
  formData:Email=new Email();
  public sendEmail = (route: string, body: any) => {
    return <any>this.http.post(this.createCompleteRoute(route, this._envUrl.urlAddress), body,{responseType: 'text'});
  }
  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}${route}`;
  }
}
