<section class="alicen mb-2">
    <div class="container">
        <h2 class="weltitle titlecolor mb-1">Yoga Classes</h2>
        <div class="col-md-12 px-0">


            <div class="col-sm-4">
                <div class="center"><img src="./assets/images/icon1.png" alt="Padmam Yoga - General Yoga" /></div>
                <h3 class="titlecolor text-center">General Yoga</h3>
                <p>General Yoga combines mind (focus and attention), energy (life-force cultivation through breathing
                    practices) and body (movement and postures). There is a magical quality that makes yoga different
                    from Western 'workouts'; yoga gives you true fitness and brings inner-strength, clarity and calm.
                    The benefit of General Yoga offers a myriad of benefits physically, mentally and emotionally to help
                    us live our lives to the fullest. Other benefit are as follows</p>

                <li class="txtleft">Decreases blood pressure</li>
                <li class="txtleft">Lowers the pulse rate</li>
                <li class="txtleft">Massages all the internal glands and organs of the body in a thorough manner which
                    prevents disease</li>

            </div>
            <div class="col-sm-4">
                <div class="center"><img src="./assets/images/icon2.png" alt="Padmam Yoga - General Yoga" /></div>
                <h3 class="titlecolor text-center">Therapy Yoga</h3>
                <p>Therapy yoga at Padmam yoga does not stop with just curing physical illness but extends holistic
                    healing to those seeking relief from any problem - be it physical, mental, emotional or spiritual.
                    Here, our students are not merely numbers or cases. Each person is taught individually with the
                    focus on dealing with the cause rather than the symptoms of the problems.</p>

                <li class="txtleft">Reduces level of stress hormone, cortisol</li>
                <li class="txtleft">Strengthens nervous system, reducing long-term stress levels</li>
                <li class="txtleft">Increases neurotransmitter dopamine, which lifts the mood to create a feeling of
                    happiness and harmony</li>
                <li class="txtleft">Gives the opportunity for introspection and inner-awareness</li>

            </div>
            <div class="col-sm-4">
                <div class="center"><img src="./assets/images/icon3.png" alt="Padmam Yoga - General Yoga" /></div>
                <h3 class="titlecolor text-center">Kids Yoga</h3>
                <p>Kids yoga is an innovative yoga training program designed exclusive for kids. One of the first child
                    yoga training programs worldwide, developed by pioneer Mr. Ravi Arumugam, enlightening results for
                    kids with ADD, ADHD, autism and self-calming techniques for all kids from toddlers to teens.</p>

                <li class="txtleft">Fun for everyone</li>
                <li class="txtleft">Enrich children's health, mind & body through fun exercise & relaxation</li>
                <li class="txtleft">Use effective self-calming techniques while making the class engaging &
                    age-appropriate</li>
                <li class="txtleft">Learn how to stay in your center & have clear, meaningful communication with kids
                </li>

            </div>

        </div>
    </div>


    <div class="container">
        <div class="col-md-12 px-0">


            <div class="col-sm-4">
                <div class="center"><img src="./assets/images/icon4.png" alt="Padmam Yoga - General Yoga" /></div>
                <h3 class="titlecolor text-center">Pregnancy Yoga</h3>
                <p>Pregnancy yoga at Padmam yoga are unique, revolutionary prenatal yoga classes for pregnant women. We
                    use traditional yoga postures (asana), adapted and beneficial for pregnancy and the common ailments
                    associated with pregnancy, along with an especially created combination of self-hypnosis and yoga
                    nidra (deep relaxation), as well as breathing techniques for pregnancy and birth (pranayama.)</p>

                <li class="txtleft">Develop stamina and strength</li>
                <li class="txtleft">Balance</li>
                <li class="txtleft">Relieves tension of lower back, hips, chest, upper back, neck and shoulders</li>
                <li class="txtleft">Calms the nervous system</li>

            </div>
            <div class="col-sm-4">
                <div class="center"><img src="./assets/images/icon5.png" alt="Padmam Yoga - General Yoga" /></div>
                <h3 class="titlecolor text-center">Power Yoga</h3>
                <p>Power Yoga is definitely an intense workout that will make you sweat. A traditional Ashtanga practice
                    follows the same series of poses and makes you hold each for five breaths before moving through a
                    Vinyasa. Power Yoga classes move with an even faster rhythm.</p>

                <li class="txtleft">Calorie burning.</li>
                <li class="txtleft">Increases stamina, strength, flexibility and tone.</li>
                <li class="txtleft">Promotes blood circulation and boosts the immune system.</li>
                <li class="txtleft">Alleviates stress and tension.</li>
                <li class="txtleft">Eliminates toxins through sweat.</li>

            </div>
            <div class="col-sm-4">
                <div class="center"><img src="./assets/images/icon6.png" alt="Padmam Yoga - General Yoga" /></div>
                <h3 class="titlecolor text-center">Corporate Yoga</h3>
                <p>Corporate Yoga is being embraced by many progressive businesses and is a great benefit. We offer a
                    range of practices from slow and meditative to energizing and active. We will get to know your staff
                    and find out the type of practice most needed. Yoga provides the benefits of relaxation/stress
                    management training and fitness.</p>

                <li class="txtleft">Reduce stress & tension</li>
                <li class="txtleft">Increase energy</li>
                <li class="txtleft">Get strong and physically fit</li>
                <li class="txtleft">Improve focus and concentration</li>
                <li class="txtleft">Improve mind set, morale and loyalty.</li>

            </div>

        </div>
    </div>

    <div class="container">
        <div class="col-md-12 px-0">


            <div class="col-sm-4">
                <div class="center"><img src="./assets/images/icon7.png" alt="Padmam Yoga - General Yoga" /></div>
                <h3 class="titlecolor text-center">Prana Yoga</h3>
                <p>'Prana' refers to the universal life force and 'ayama' means to regulate or lengthen. Prana is the
                    vital energy needed by our physical and subtle layers, without which the body would perish. It is
                    what keeps us alive. Pranayama is the control of prana through the breath. These techniques rely on
                    breathing through the nostrils.</p>

                <li class="txtleft">Reduced breathing rate</li>
                <li class="txtleft">Increases Life longevity</li>
                <li class="txtleft">Blood circulation increases</li>
                <li class="txtleft">Healthy heart</li>
                <li class="txtleft">Better functioning of body parts</li>

            </div>
            <div class="col-sm-4">
                <div class="center"><img src="./assets/images/icon8.png" alt="Padmam Yoga - General Yoga" /></div>
                <h3 class="titlecolor text-center">Aqua Yoga</h3>
                <p>Aqua yoga is a low impact aquastic exercise, performing yoga in warm water.</p>

                <li class="txtleft">Increased range of motion</li>
                <li class="txtleft">Greater awareness of how to improve breathing and correct erroneous breathing
                    patterns</li>
                <li class="txtleft">Improving balance and core awareness</li>
                <li class="txtleft">Releasing tension and stress
                </li>

            </div>


        </div>
    </div>

</section>