<section class="alicen mb-2">

    <div class="text-center">
        <h2 class="titlecen">Contact Us</h2>
    </div>
    <div class="container">
        <div class="col-md-12 px-0 mb-2">
            <div class="col-sm-6 pl-0 pr-4">
                <h3>Director : Ravi Arumugam</h3>
                <p>Mob : +91 90032 88211 | Email: padmamyoga2010@gmail.com </p>
                <hr>
                <h4><strong>Office Address:</strong></h4>
                <p>No.35, Sakthi Nagar Main Rd, Near PRIME CARE NURSING HOME, Balaji Nagar, Adambakkam, Chennai- 600088</p>
                <hr>
                <p>Weekdays: Monday to Friday | Weekends: Saturday & Sunday<br>
                    <i>*Age: From above 6 years.</i>
                </p>
            </div>

            <div class="col-sm-6 mt-2 pl-0">
                <form class="text-left">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" placeholder="Name" [(ngModel)]="SendEmail.name"
                            name="Name" required />
                    </div>
                    <div class="form-group">
                        <label>Mobile</label>
                        <input type="tel" class="form-control" placeholder="Mobile" [(ngModel)]="SendEmail.mobile"
                            name="Mobile" required />
                    </div>
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" placeholder="Email" [(ngModel)]="SendEmail.email"
                            name="Email" required />
                    </div>
                    <div class="form-group">
                        <label>Message</label>
                        <input type="text" class="form-control" placeholder="Message" [(ngModel)]="SendEmail.message"
                            name="Message" required />
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="ErrorMessage!=null && ErrorMessage!=''">
                        {{ErrorMessage}}
                    </div>
                    <div class="alert alert-success" role="alert" *ngIf="IsEmailSent">
                        Thank you!!! We received your message.
                    </div>
                    <div class="form-group">
                        <input type="button" class="btn btn-primary" (click)="send(SendEmail)" value="Send Message" />
                    </div>
                    
                </form>
            </div>
        </div>
        <div class="col-md-12 px-0 mb-2">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15550.969097079189!2d80.203528!3d12.9883291!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcdb6cd42e9915d87!2sPadmam+yoga!5e0!3m2!1sen!2sin!4v1495285661667"
                height="400px" frameborder="0" style="border:0;width: 100%;" allowfullscreen></iframe>
        </div>
    </div>
</section>
